$(function () {
  // Carrega indicadores dos slides manualmente
  var $indicators = $('#slider .carousel-indicators')
  var slides = 0

  $('#slider .carousel-inner .item').each(function () {
    var cssClass = (slides === 0) ? 'active' : ''
    var el = '<li data-target="#slider" data-slide-to="' + slides + '" class=" ' + cssClass + '"></li>'
    $indicators.append(el)
    slides++
  })

  // Determina que o primeiro está ativo
  $('#slider .carousel-inner .item:eq(0)').addClass('active')

  // Ativa Slider
  $('.carousel').carousel()

  // Form validation
  $(window).on('ajaxInvalidField', function (event, fieldElement, fieldName, errorMsg, isFirst) {
    $(fieldElement).closest('.input-group').addClass('has-error')
  })

  $(document).on('ajaxPromise', '[data-request]', function () {
    $(this).closest('form').find('.input-group.has-error').removeClass('has-error')
  })

  // $(window).scroll(function () {
  //   if ($(this).scrollTop() > 200) {
  //     $('.navbar-fixed-top').fadeIn(500)
  //   } else {
  //     $('.navbar-fixed-top').fadeOut(500)
  //   }
  // })
})
